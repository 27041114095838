import { Container, Box, Typography, Button, Grid } from '@mui/material';
import { getImageUrl } from '../../utils';

const CancerfondenAdvert = () => (
  <Container 
    sx={{ 
      padding: { xs: '1rem', md: '2rem' },  // Reduced padding
      backgroundColor: '#f5f5f5',
      borderRadius: '12px',  // Slightly smaller border radius
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.12)', 
      maxWidth: '900px',  // Slightly smaller max-width
      margin: '0.5rem auto',  // Reduced margin
      position: 'relative',
    }}
  >
    {/* Pink Logo */}
    

    {/* Main Content */}
    <Grid 
      container 
      spacing={3}  // Less spacing
      alignItems="center" 
      direction={{ xs: 'column', md: 'row' }} 
    >
      {/* Right-Side Image */}
      <Grid 
        item 
        xs={8} 
        md={3} 
        order={{ xs: 1, md: 2 }} 
        sx={{
          marginTop: { xs: '2rem', md: 0 },  // Less margin
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img 
            src={getImageUrl('pink-logo.png')} 
            alt="Birgitta Möller"
            style={{
              width: '100%',
              maxWidth: { xs: '120px', md: '160px' },  // Reduced image size
              borderRadius: '10px',
              
            }}
          />
        </Box>
      </Grid>

      {/* Content Area */}
      <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
        {/* Header */}
        <Typography 
          variant="h6"  // Reduced from h5
          sx={{  
            textAlign: 'center',
            fontWeight: 'bold', 
            marginBottom: '0.5rem',  // Reduced margin
            fontSize: { xs: '1.1rem', md: '1.6rem' }, // Slightly smaller font
            marginTop: { xs: '0.5rem', md: '2rem' }, // Reduced top margin
          }}
        >
          Insamling till Cancerfonden
        </Typography>

        {/* Subheader */}
        <Typography 
          variant="body2"  // Reduced text size
          sx={{ 
            textAlign: 'center',
            color: '#444', 
            fontWeight: '500', 
            marginBottom: '0.75rem',  // Less bottom margin
            fontSize: { xs: '0.85rem', md: '1rem' },
            paddingX: { xs: '0.5rem', md: '2rem' }, 
          }}
        >
          Pilates Höganäs samlar in pengar till Cancerfonden i minne av Birgitta Möller.
        </Typography>

        {/* Buttons */}
        <Box sx={{ display: 'flex', gap: '0.75rem', justifyContent: 'center' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#444',
              color: '#fff',
              padding: { xs: '0.4rem 1.2rem', md: '0.6rem 1.8rem' }, // Reduced padding
              borderRadius: '10px',
              fontWeight: '400',
              textTransform: 'none',
              fontSize: { xs: '0.75rem', md: '0.85rem' },
              '&:hover': {
                backgroundColor: '#7c7c7c',
              },
            }}
            href="https://www.cancerfonden.se/insamlingar/pilates-hoganas-samlar-in-pengar-till-cancerfonden-i-minne-av-birgitta-moller"
            target="_blank"
            rel="noopener noreferrer" 
          >
            Insamlingen
          </Button>

          <Button
            variant="contained"
            sx={{
              backgroundColor: '#DD0D7D',
              color: '#fff',
              padding: { xs: '0.4rem 1rem', md: '0.6rem 1.8rem' },
              borderRadius: '10px',
              fontWeight: '600',
              textTransform: 'none',
              fontSize: { xs: '0.75rem', md: '0.9rem' },
              '&:hover': {
                backgroundColor: '#E98FBF',
              },
            }}
            href="https://www.cancerfonden.se/stod-oss/skank-till-en-insamling/pilates-hoganas-samlar-in-pengar-till-cancerfonden-i-minne-av-birgitta-moller"
            target="_blank"
            rel="noopener noreferrer" 
          >
            Bidra till Insamlingen
          </Button>
        </Box>
      </Grid>
    </Grid>
  </Container>
);

export default CancerfondenAdvert;
