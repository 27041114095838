// src/components/CancerfondenAdvert.js
import { Container, Box, Typography, Button, Grid } from '@mui/material';
import { getImageUrl } from '../../utils';

const Samling = () => (
  <Container 
    sx={{ 
      padding: { xs: '1.5rem', md: '3rem' },
      backgroundColor: '#f5f5f5',
      borderRadius: '16px', 
      boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.15)', 
      Width: '1000px',
      margin: '1rem auto',
      position: 'relative',
    }}
  >
    {/* Pink Logo */}
    <Box
      sx={{
        position: 'absolute',
        top: '20px',
        left: '20px',
        zIndex: 1,
      }}
    >
      <img 
        src={getImageUrl('pink-logo.png')} 
        alt="Pink Logo"
        style={{
          maxWidth: '16rem', // Smaller logo for mobile screens
          height: 'auto',
        }}
      />
    </Box>

    {/* Main Content */}
    <Grid 
      container 
      spacing={4} 
      alignItems="center" 
      direction={{ xs: 'column', md: 'row' }} // Stacks vertically on small screens
    >
      {/* Right-Side Image */}
      <Grid 
        item 
        xs={8} 
        md={4} 
        order={{ xs: 1, md: 2 }} 
        sx={{
          marginTop: { xs: '6rem', md: 0 }, // Adds space on small screens
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img 
            src={getImageUrl('birgitta.png')} 
            alt="Birgitta Möller"
            style={{
              width: '100%',
              maxWidth: { xs: '150px', md: '200px' }, // Smaller image for mobile screens
              borderRadius: '12px',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          />
        </Box>
      </Grid>

      {/* Content Area */}
      <Grid item xs={12} md={8} order={{ xs: 2, md: 1 }}>
        {/* Header */}
        <Typography 
          variant="h5" // Reduced font size for mobile
          sx={{  
            paddingX: { xs: '0.5rem', md: '2rem' },
            textAlign: 'center',
            fontWeight: 'bold', 
            marginBottom: '1rem', 
            fontSize: { xs: '1.25rem', md: '2rem' },
            marginTop: { xs: '1rem', md: '4rem' },
          }}
        >
          15. Februari 2025
        </Typography>

        {/* Subheader */}
        <Typography 
          variant="body1" 
          sx={{ 
            textAlign: 'justify',
            color: '#444', 
            fontWeight: '600', 
            marginBottom: '1rem', 
            fontSize: { xs: '0.9rem', md: '1.1rem' },
            paddingX: { xs: '1rem', md: '3rem' }, // Adds space on both sides
          }}
        >
          Pilates Höganäs samlar in pengar till Cancerfonden
        </Typography>

        {/* Description */}
        <Typography 
          variant="body2" 
          sx={{ 
            color: '#444', 
            lineHeight: 1.6, 
            fontSize: { xs: '0.85rem', md: '1rem' },
            marginBottom: '1.5rem', 
            textAlign: 'justify',
            paddingX: { xs: '1rem', md: '3rem' },
          }}
        >
          Den <strong>15 februari</strong> arrangerar vi en insamlingsdag på studion till minne av vår älskade <strong>Birgitta Möller,</strong> som somnade in den 26 december 2024. <br></br> Dagen kommer att vara fylld med <strong>träning</strong>, <strong>gemenskap</strong> och möjligheter att stödja <strong>Cancerfondens </strong>viktiga arbete. <br></br><br></br>

            Vi håller igång mellan <strong>09:00-15:00</strong> och alla är <strong>välkomna!</strong> Oavsett om du vill delta i träningen eller bara komma förbi och dricka kaffe <strong>din närvaro gör skillnad.</strong> Mina fantastiska kollegor och instruktörer från hela landet skänker sin tid och energi denna dag för att <strong>vi tillsammans ska kunna bidra till Cancerfonden.</strong>
          <br /><br />
           
          Kom och svettas, skänk en slant och hjälp oss göra skillnad!
          <br></br> <br></br>
          Boka en klass här för <strong>100kr</strong> oavsett om du har medlemskap eller inte, <strong>det är för alla!</strong>
          
          <br></br><br></br><strong>Alla pengar går oavkortat till Cancerfonden</strong>
           
        </Typography>

        {/* Buttons */}
        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
          
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#DD0D7D',
              color: '#fff',
              padding: { xs: '0.5rem 1rem', md: '0.75rem 2rem' },
              borderRadius: '12px',
              fontWeight: '600',
              textTransform: 'none',
              fontSize: { xs: '0.8rem', md: '1rem' },
              '&:hover': {
                backgroundColor: '#E98FBF',
              },
            }}
            href="https://pilateshoganas.wondr.se/w_booking/wizards/book/67a32d96-b248-48fc-9c9d-01bc0a100864"
            target="_blank"
            rel="noopener noreferrer" 
          >
            Boka Klass
          </Button>
        </Box>
      </Grid>
    </Grid>
  </Container>
);

export default Samling;
