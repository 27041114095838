import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopBanner from './components/landingpage/TopBanner';
import Navbar from './components/landingpage/Navbar';
import Hero from './components/landingpage/Hero';
import About from './components/landingpage/About';
import Memberships from './components/landingpage/Memberships';
import Contact from './components/landingpage/Contact';
import Footer from './components/landingpage/Footer';
import Schedule from './components/landingpage/Schedule';
import Coaches from './components/landingpage/Coaches';
import MembershipInfo from './components/sidepages/Membership-info';
import AboutInfo from './components/sidepages/About-info';
import InstagramFeed from './components/landingpage/InstagramFeed';
import Klasses from './components/sidepages/Klasses'; // Import the Klasses component
import PersonalTraining from './components/landingpage/PersonalTraining';
import PersonalTrainingInfo from './components/sidepages/PersonalTraining-info';
import InfoRegler from './components/sidepages/InfoRegler';
import BraAttVeta from './components/sidepages/BraAttVeta';
import CancerfondenAdvert from './components/landingpage/CancerfondenAdvert';
import Schema from './components/sidepages/Schema';
import Samling from './components/landingpage/samling'

function App() {
  return (
    <Router>
      <TopBanner />
      <Navbar />
      <div className="container"> {/* Main wrapper for all content */}
        <Routes>
          <Route path="/" element={
            <>
             <Hero />
              
              <Samling />
              <CancerfondenAdvert />
              <Schedule />
              
              <About />
              <Memberships />
              <PersonalTraining />
              <Coaches />
              <InstagramFeed />
              <Contact />
            </>
          } />
          <Route path='sidepages/schema' element={<Schema />}/>
          <Route path="/sidepages/membership-info" element={<MembershipInfo />} />
          <Route path="/sidepages/about-info" element={<AboutInfo />} />
          <Route path="/sidepages/klasses" element={<Klasses />} /> 
          <Route path="/sidepages/personaltraining-info" element={<PersonalTrainingInfo />} />
          <Route path='/sidepages/bra-att-veta' element={<BraAttVeta />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}


export default App;
